<template>
    <div class="review-card">
        <rating-info :content="content" class="mb-3"></rating-info>

        <article ref="refArticle">
            {{ content.comment }}

            <button v-if="isOverflowing" @click="openReviewModal()">อ่านต่อ</button>
        </article>
            
        <store-display :content="content"></store-display>

        <modal-review
            @close="closeReviewModal()"
            :content="content"
            :isDisplay="isOpenReview">
        </modal-review>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import ModalReview from '@/components/review/ModalReview.vue';
import RatingInfo from '@/components/review/RatingInfo.vue';
import StoreDisplay from '@/components/review/StoreDisplay.vue';

export default {
    components: {
        ModalReview,
        RatingInfo,
        StoreDisplay
    },
    data() {
        return {
            helper: Helper,
            routerPath: RouterPath,
            isOverflowing: false,
            isOpenReview: false
        };
    },
    props: {
        content: Object
    },
    mounted() {
        this.$nextTick(() => {
            this.checkArticleOverflow();
        });
    },
    methods: {
        checkArticleOverflow() {
            const article = this.$refs.refArticle;

            if (article) {
                this.isOverflowing = article.scrollHeight > article.clientHeight;
            }
        },
        openReviewModal() {
            this.isOpenReview = true;
        },
        closeReviewModal() {
            this.isOpenReview = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.review-card {
    background: #fff;
    padding: 10px;

    article {
        position: relative;
        font-size: 13px;
        line-height: 21px;
        height: 42px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 55px;

        button {
            position: absolute;
            bottom: 0;
            right: 0;
            background: #fff;
            border: none;
            outline: none;
            font-weight: bold;
            font-size: 13px;
            line-height: 18px;
            color: #0d6efd;
            text-decoration: underline;

            &:hover {
                opacity: 1;
            }
        }
    }
}
</style>