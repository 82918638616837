<template>
	<div class="reviews">
		<div class="container">
			<section class="content-blog-grid mt-3 mb-2">
				<h1>รีวิวจากผู้ใช้บริการ</h1>
				<div class="loading" v-if="!isResultReady">
					<div class="item">
						<loader-content :isDisplay="true"></loader-content>
					</div>
				</div>
				<template v-if="isResultReady">
					<div class="row">
						<div class="col-sm-12 col-md-6" v-for="(item, index) in resultList" :key="index">
							<review-card
								class="mb-3"
								:content="item">
							</review-card>
						</div>
					</div>
					<paging
						@page="changePage($event)"
						:activePage="activePage"
						:totalPage="totalPage"
						class="mt-4">
					</paging>
				</template>
			</section>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import ReviewService from '@/services/reviewService';
import ReviewCard from '@/components/review/ReviewCard';

export default {
	components: {
		ReviewCard
	},
	data() {
		return {
			isResultReady: false,
			resultList: [],
			activePage: 1,
			totalPage: 0
		};
	},
	mounted() {
		this.getReviewList();
    },
    methods: {
		async getReviewList() {
            this.isResultReady = false;

			const param = this.setupParamContent();
            const result = await ReviewService.getReviewList(param);

			this.resultList = result.data.resultList;
			this.totalPage = result.data.totalPage;
            this.isResultReady = true;
        },
		setupParamContent() {
			const param = {
				page: this.activePage
			};

			return param;
		},
		changePage(page) {
			this.activePage = page;
			this.getReviewList();
		}
    },
	metaInfo() {
		const title = 'รีวิวจากผู้ใช้บริการ | YELLOWTiRE';
		const desc = 'รีวิวจากผู้ใช้บริการ YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.reviews {
	padding: 15px 0;

	@media only screen and (max-width: $screenMedium) {
		padding-top: 0;
	}

	h1 {
		font-family: 'Open Sans', 'Kanit';
		font-weight: bold;
		font-size: 25px;
		margin-bottom: 20px;
	}
}
</style>